import * as React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import "../../AboutUs.css";

interface Props {
  img: string;
  name: string;
  position: string;
  resume: string;
  linkedin?: string;
  email?: string;
  mail?: string;
  web?: string;
}

export default function TeamMembers({
  img,
  name,
  position,
  resume,
  linkedin,
  email,
  mail,
  web,
}: Props) {
  const url = `./img/${img}`;

  const showIcon = (
    <Grid item className="icons">
      {email && (
        <IconButton aria-label="email" href={email}>
          <img src="./img/icons/mail-icon.svg" alt="email" />
        </IconButton>
      )}
      {linkedin && (
        <IconButton aria-label="linkedin" href={linkedin} target="_blank">
          <img src="./img/icons/linkedin-icon.svg" alt="linkedin" />
        </IconButton>
      )}
      {web && (
        <IconButton aria-label="web" href={web} target="_blank">
          <LanguageIcon />
        </IconButton>
      )}
    </Grid>
  );

  return (
    <>
      <div className="icon-container">
        <img src={url} alt={name} width="100%" />
      </div>
      <Typography variant="h3" py={2}>
        {name}
      </Typography>
      <Typography
        pb={2}
        sx={{
          fontWeight: 300,
          fontSize: 16,
          maxWidth: 300,
        }}
      >
        {position}
      </Typography>
      <Typography
        py={2}
        sx={{
          fontWeight: 300,
          fontSize: 14,
          lineHight: 24,
          maxWidth: 300,
          minHeight: 145,
        }}
      >
        {resume}
      </Typography>

      {(linkedin || email || web) && showIcon}
    </>
  );
}
