export const teamDataEs = [
  {
    img: "leo-photo.png",
    name: "Leonardo Hsu",
    position: "Founder & CEO",
    resume:
      "+3 años de experiencia en inversiones alternativas cubriendo varios activos de inversión en USA, LATAM y Europa. Experiencia: Investment Associate en DLE Group AG, Strategy Analyst en Family Office, y Research Analyst en JLL.",
    linkedin: "https://www.linkedin.com/in/leonardohsu/",
    email: "mailto:l.hsu@taroko.la",
    mail: "l.hsu@taroko.la",
  },
  {
    img: "christian-photo.png",
    name: "Christian Cavanagh",
    position: "Co-Founder & CCO",
    email: "mailto:c.cavanagh@taroko.la",
    resume:
      "+20 años de experience en asset management. Experiencia: CIO, Director & Partner en Delta Asset Management, CIO & Partner en RJ Delta Asset Management, y CEO & Chairman en BBVA Asset Management.",
    linkedin: "https://ar.linkedin.com/in/christian-cavanagh-34994a4",
  },
  {
    img: "caro-photo.png",
    name: "Carolina Malagueño",
    position: "Head of Product",
    resume:
      "+10 años de experiencia en producto y tecnología. Trabajó en 1 unicornio y 2 start-ups de alto crecimiento en LATAM. Experiencia: Sr Product Owner en Lemon Cash, Sr Product Owner en Kavak Capital, y Sr Product Owner en Naranja X.",
    linkedin: "https://www.linkedin.com/in/caro-malagueno/",
    email: "mailto:c.malagueno@taroko.la",
    mail: "c.malagueno@taroko.la",
  },
  {
    img: "cio-logo.png",
    name: "CIO",
    position: "Advisor & Selector de Fondos",
    resume:
      "Bajo la dirección de Rodrigo González y Eduardo Fantini, CIO actúa como el OCIO de más de 10 clientes institucionales y oficinas familiares en todo LATAM.",
  },
  {
    img: "sebastian-photo.png",
    name: "Sebastián Caronni",
    position: "Advisor & Commercial Partner",
    resume:
      "+22 años de experiencia en inversiones alternativas, asset & wealth management. Experiencia: Board Member en Cohen, MD & Partner en LarrainVial Chile y Argentina, ED en Santander Investments.",
  },
  {
    img: "miguel-photo.png",
    name: "Miguel Cauvi",
    position: "Advisor - Strategy & Operations",
    resume:
      "MD en Mercado InterCuentas, COO en Strenta Family Office, Managing Director en Insigneo, MD en Hencorp Group.",
  },
  {
    img: "lorenzo-photo.png",
    name: "Lorenzo Aracena",
    position: "Advisor - Cybersecurity & Compliance",
    resume:
      "+15 años de experiencia en ciberseguridad y tecnología. Trabajó como Cybersecurity Sr Director en Konfio, Cybersecurity Director en Kavak y CISO en Naranja X.",
  },
  {
    img: "mauro-photo.png",
    name: "Mauro Szuchman",
    position: "Advisor -  Technology & Product",
    resume:
      "+10 años de experiencia en tecnología y producto en LATAM y en el Sudeste Asiático. Experiencia: CPO, CTO y Senior VP .",
  },
  {
    img: "charles-photo.png",
    name: "Charles Andros",
    position: "Investment Committee",
    resume:
      "Founding Partner & Chief Investment Officer @ Bay Point Advisors. Desde su inicio, ha estado a cargo de la inversión de 1.6 mil millones de dólares en los Estados Unidos.",
  },
  {
    img: "cabsa-logo.png",
    name: "CABSA",
    position: "Partner Comercial Estratégico",
    resume:
      "Cabsa es una empresa de colocación de activos y estructuración financiera que ofrece una amplia gama de productos en las Américas.",
  },
];

export const teamDataEn = [
  {
    img: "leo-photo.png",
    name: "Leonardo Hsu",
    position: "Founder & CEO",
    resume:
      "+3 years of experience in alternative investments covering various asset classes in USA, LATAM and Europe. Experience: Investment Associate at DLE Group AG, Strategy Analyst at Family Office, and Research Analyst at JLL.",
    linkedin: "https://www.linkedin.com/in/leonardohsu/",
    email: "mailto:l.hsu@taroko.la",
    mail: "l.hsu@taroko.la",
  },
  {
    img: "christian-photo.png",
    name: "Christian Cavanagh",
    position: "Co-Founder & CCO",
    resume:
      "+20 years of experience in asset management. Experience: CIO, Director & Partner at Delta Asset Management, CIO & Partner at RJ Delta Asset Management, and CEO & Chairman at BBVA Asset Management.",
    linkedin: "https://ar.linkedin.com/in/christian-cavanagh-34994a4",
    email: "mailto:c.cavanagh@taroko.la",
  },
  {
    img: "caro-photo.png",
    name: "Carolina Malagueño",
    position: "Head of Product",
    resume:
      "+10 years of experience in product & tech. Worked in 1 unicorn and 2 fast-growing start-ups in LATAM. Experience: Sr Product Owner at Lemon Cash, Sr Product Owner at Kavak Capital, and Sr Product Owner at Naranja X.",
    linkedin: "https://www.linkedin.com/in/caro-malagueno/",
    email: "mailto:c.malagueno@taroko.la",
    mail: "c.malagueno@taroko.la",
  },
  {
    img: "cio-logo.png",
    name: "CIO Investment Office",
    position: "Advisor & Fund Selection",
    resume:
      "Led by Rodrigo Gonzalez & Eduardo Fantini, CIO serves as the OCIO of +10 institutional clients and family offices across LATAM.",
  },
  {
    img: "sebastian-photo.png",
    name: "Sebastián Caronni",
    position: "Advisor & Commercial Partner",
    resume:
      "+22 yrs of exp in alternatives, asset & wealth management. Exp: Board Member at Cohen, MD & Partner at LarrainVial Chile and Argentina, ED at Santander Investments.",
  },
  {
    img: "miguel-photo.png",
    name: "Miguel Cauvi",
    position: "Advisor - Strategy & Operations",
    resume:
      "Ex: MD at Mercado InterCuentas, COO at Strenta Family Office, Managing Director at Insigneo, MD at Hencorp Group.",
  },
  {
    img: "lorenzo-photo.png",
    name: "Lorenzo Aracena",
    position: "Advisor - Cybersecurity & Compliance",
    resume:
      "+15 years of experience in cybersecurity & tech. Worked as Cybersecurity Sr Director at Konfio, Cybersecurity Director at Kavak, CISO at Naranja X.",
  },
  {
    img: "mauro-photo.png",
    name: "Mauro Szuchman",
    position: "Advisor -  Technology & Product",
    resume:
      "+10 yrs of experience in product and tech in LATAM and SouthEast Asia. Exp: CPO, CTO and Senior VP positions.",
  },
  {
    img: "charles-photo.png",
    name: "Charles Andros",
    position: "Investment Committee",
    resume:
      "Founding Partner & Chief Investment Officer @ Bay Point Advisors. Since inception he has been in charge of the deployment of $1.6B across US.",
  },
  {
    img: "cabsa-logo.png",
    name: "CABSA",
    position: "Strategic Commercial Partner",
    resume:
      "Cabsa is a cross-border asset-raising and financial structurer company offering a broad spectrum of products across Americas.",
  },
];
