import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Podcast from "../pages/Podcast/Podcast";
import WaitingList from "../pages/WaitingList/WaitingList";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "../pages/Terms/Terms";
import Declaimer from "../pages/Declaimer/Declaimer";
import TarokoTalks from "../pages/TarokoTalks/TarokoTalks";
import EpisodeDetail from "../pages/TarokoTalks/EpisodeDetail/EpisodeDetail";
import AboutUs from "../pages/AboutUs/AboutUs";
import Platform from "../pages/OurPlatform/Platform";

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Podcast />} />
      <Route path="/waiting-list" element={<WaitingList />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/declaimer" element={<Declaimer />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/taroko-talks" element={<TarokoTalks />} />
      <Route path="/taroko-talks/episode" element={<EpisodeDetail />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/our-platform" element={<Platform />} />
      <Route path="*"></Route>
    </Routes>
  );
}
