import React, { useEffect } from "react";
import "./AboutUs.css";
import Header from "../../components/commons/Header/Header";
import Footer from "../../components/commons/Footer/Footer";
import { Typography, Grid, Button, Fab } from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { teamDataEs, teamDataEn } from "./Team/TeamsConstants";
import TeamMembers from "./Team/TeamMembers/TeamMembers";

export default function AboutUs() {
  const [t, i18n] = useTranslation("global");
  const lng = i18n.resolvedLanguage;
  const teamData = lng === "es" ? teamDataEs : teamDataEn;

  const ScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    ScrollTop();
  }, []);

  return (
    <div className="team-section" id="team">
      <Header />
      <Grid
        container
        direction="row"
        py={6}
        px={{ xl: 15, md: 8, xs: 2 }}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          color: "#fff",
          background:
            "linear-gradient(45deg, #101828 0%, #293346 50%, #475467 100%)",
        }}
      >
        <Grid item xs={12} md={7} xl={6}>
          <Typography variant="h2">{t("team.title")}</Typography>
          <Typography variant="h3" className="subtitle">
            {t("team.text")}
            <span>{t("team.text-bold")}</span>
          </Typography>
        </Grid>
        <Grid
          xs={12}
          md={4}
          pt={2}
          mb={{ xs: 4 }}
          sx={{
            textAlign: "right",
            "@media (max-width: 450px)": {
              textAlign: "left",
            },
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              padding: "13px 20px",
              borderRadius: 3,
            }}
            href="https://www.linkedin.com/company/taroko-investments"
            target="_blank"
          >
            {t("team.button")}
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={4} py={12} px={{ xl: 15, md: 8, xs: 2 }}>
        {teamData.map((item: any, key: any) => (
          <Grid item md={3} xs={12} key={key} pb={{ md: 4, xs: 2 }}>
            <TeamMembers
              img={item.img}
              name={item.name}
              position={item.position}
              resume={item.resume}
              {...item}
            />
          </Grid>
        ))}
      </Grid>

      <Fab className="btn-up" color="primary" onClick={ScrollTop}>
        <KeyboardArrowUpIcon />
      </Fab>

      <Footer />
    </div>
  );
}
